import Rails from '@rails/ujs';

window.addEventListener('turbo:load', function(){
    if( document.getElementById('show-caption-status') != null ){
        if( document.getElementById('show-caption-status').value == "generating" || document.getElementById('show-caption-status').value == "editing" ){

            var showCaptionInterval;
            let captionId = document.getElementById('caption-identifier');
            captionId = captionId.getAttribute('data-id');
            let thisRefreshUrl = "/captions/" + captionId + "/refresh";

            function setShowCaptionIntervalWithTurbo(intervalFunction, milliseconds) {
                showCaptionInterval = setInterval(intervalFunction, milliseconds);
                window.addEventListener('turbo:before-cache', function(){
                    clearTimeout(showCaptionInterval);
                });
                window.addEventListener('turbo:before-render', function(){
                    clearTimeout(showCaptionInterval);
                });
            }

            function refreshThisPartial() {
                setTimeout(function(){
                    Rails.ajax({
                        type: 'POST',
                        url: thisRefreshUrl
                    });
                }, 2000, thisRefreshUrl);

                if( document.getElementById('show-caption-status') != null ){
                    if( document.getElementById('show-caption-status').value == "ready" ){
                        clearInterval(showCaptionInterval);
                    }
                }
            }

            setShowCaptionIntervalWithTurbo(refreshThisPartial, 2000);
        }
    }
});