import Rails from '@rails/ujs';

function setIntervalWithTurbo(intervalFunction, milliseconds) {
    var interval = setInterval(intervalFunction, milliseconds);
    window.addEventListener('turbo:before-cache', function(){
        clearTimeout(interval);
    });
    window.addEventListener('turbo:before-render', function(){
        clearTimeout(interval);
    });
}

window.addEventListener('turbo:load', function(){
    
    function refreshResults() {
        var accountPending = document.getElementById('pending_account_id');
        if( accountPending != null){
            var searchRefreshUrl = "/refresh_my_account";
            Rails.ajax({
                type: 'GET',
                url: searchRefreshUrl
            });
        }
    }
    setIntervalWithTurbo(refreshResults, 2000);

    function refreshInsights() {
        var accountPending = document.getElementById('insights-pending');
        if( accountPending != null){
            var searchRefreshUrl = "/refresh_my_insights/" + document.getElementById('period').value;
            Rails.ajax({
                type: 'GET',
                url: searchRefreshUrl,
                success: function() {
                        
                    let chartInits = document.querySelectorAll('.apex-chart-initializer');
                    for ( let i = 0; i < chartInits.length; i++ ) {
                        let chartKeys = chartInits[i].getAttribute('data-data-keys').split(',');
                        let chartValues = chartInits[i].getAttribute('data-data-values').split(',');
                        let chartData = [];

                        for( let i = 0; i < chartKeys.length; i++ ) {
                            chartData[i] = {};
                            chartData[i]["x"] = parseInt(chartKeys[i]);
                            chartData[i]["y"] = parseInt(chartValues[i]);
                        }
                        
                        var thisChart = new ApexCharts( chartInits[i], 
                            {
                                "chart": {
                                    "id": chartInits[i].getAttribute('id'),
                                    "height": 64,
                                    "width": "100%",
                                    "sparkline": {
                                        "enabled": true
                                    },
                                    "type": chartInits[i].getAttribute('data-chart-type'),
                                },
                                "colors": [
                                    "#9f7aea",
                                    "#e4e3f3"
                                ],
                                "dataLabels": {
                                    "enabled":false
                                },
                                "defer": false,
                                "tooltip": {
                                    "enabled":false
                                },
                                "series":[
                                    {
                                       "name":chartInits[i].getAttribute('data-chart-name'),
                                       "data":chartData
                                    }
                                ]
                            }
                        );
                        thisChart.render();
                    }
                    /*
                    let chartScripts = document.querySelectorAll('[apexcharts-rb]');
                    for (let i = 0; i < chartScripts.length; i++) {
                        let tempScript = chartScripts[i];
                        chartScripts[i].parentNode.removeChild(chartScripts[i]);

                        let newScript = document.createElement("script");
                        newScript.textContent = "(function(){" + tempScript.innerHTML + "}());";
                        document.querySelector('.dashboard-wrapper').appendChild(newScript);
                    }
                    */
                }
            });
        }
    }
    setIntervalWithTurbo(refreshInsights, 2000);
});