import Rails from '@rails/ujs';

export function setIntervalWithTurbo(intervalFunction, milliseconds) {
    var interval = setInterval(intervalFunction, milliseconds);
    window.addEventListener('turbo:before-cache', function(){
        clearTimeout(interval);
    });
    window.addEventListener('turbo:before-render', function(){
        clearTimeout(interval);
    });
}

export function setTimeoutWithTurbo(timeoutFunction, milliseconds, timeoutFunctionVariable) {
    var timeout = setTimeout(timeoutFunction(timeoutFunctionVariable), milliseconds);
    window.addEventListener('turbo:before-cache', function(){
        clearTimeout(timeout);
    });
    window.addEventListener('turbo:before-render', function(){
        clearTimeout(timeout);
    });
}