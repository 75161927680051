import Rails from '@rails/ujs';

export function setIntervalWithTurbo(intervalFunction, milliseconds) {
    var interval = setInterval(intervalFunction, milliseconds);
    window.addEventListener('turbo:before-cache', function(){
        clearTimeout(interval);
    });
    window.addEventListener('turbo:before-render', function(){
        clearTimeout(interval);
    });
}
export function setTimeoutWithTurbo(timeoutFunction, milliseconds, timeoutFunctionVariable) {
    var timeout = setTimeout(timeoutFunction(timeoutFunctionVariable), milliseconds);
    window.addEventListener('turbo:before-cache', function(){
        clearTimeout(timeout);
    });
    window.addEventListener('turbo:before-render', function(){
        clearTimeout(timeout);
    });
}

window.addEventListener('turbo:load', function(){

    var checkboxes = document.getElementsByClassName('hashtag-checkbox');
    var checkboxesareChecked = false;
    
    var pending = document.getElementsByClassName('hashtag-pending');

    function getCategoryAjax(categoryRefreshUrl) {
        //console.log('refreshing partial...');
        //console.log('category refresh url:' + categoryRefreshUrl);
        Rails.ajax({
            type: 'GET',
            url: categoryRefreshUrl
        });
    }

    if( document.getElementsByClassName('btn--click-text--loading')[0] != null ) {
        document.getElementsByClassName('btn--click-text--loading')[0].addEventListener('click', function(){
            this.innerHTML = 'Loading...';
        });
    }

    if( document.getElementById('category-show-suggested-button') != null ){
        document.getElementById('refresh-suggestions-button').onclick = function(){
            if( document.getElementById('suggested_status') != null ){
                document.getElementById('suggested_status').value = "building";
            }
            document.getElementById('refresh-suggestions-button').blur();
            document.getElementById('suggested-hashtags').classList.add('hidden');
            document.getElementById('suggested-hashtags-form').classList.add('hidden');
            document.getElementById('refreshing-suggestion').classList.remove('hidden');
            let categoryId = document.getElementById('category-identifier');
            let query = document.getElementById('query').value;
            categoryId = categoryId.getAttribute('data-id');
            let suggestionRefreshUrl = "/collections/" + categoryId + "/refresh_suggestion/?skip=yes&query=" + query;
            
            window.scrollTo(0,0);

            var suggestionInterval;
            function setSuggestionIntervalWithTurbo(intervalFunction, milliseconds) {
                suggestionInterval = setInterval(intervalFunction, milliseconds);
                window.addEventListener('turbo:before-cache', function(){
                    clearTimeout(suggestionInterval);
                });
                window.addEventListener('turbo:before-render', function(){
                    clearTimeout(suggestionInterval);
                });
            }

            function refreshSuggestions() {
                setTimeout(function(){
                    Rails.ajax({
                        type: 'POST',
                        url: suggestionRefreshUrl
                    });
                },2000,suggestionRefreshUrl);

                if( document.getElementById('suggested_status') != null ){
                    if( document.getElementById('suggested_status').value == "built" ){
                        clearInterval(suggestionInterval);
                    }
                }
            }


            setSuggestionIntervalWithTurbo(refreshSuggestions, 2000);

        }

        if( document.getElementById('category-show-suggested-button').classList.contains("btn--secondary-outline--active") ){
            if( document.getElementById('suggested_status') != null ){
                if( document.getElementById('suggested_status').value == "building" ){
                    if( !document.getElementById('refresh-suggestions-button').classList.contains('hidden') ){
                        document.getElementById('refresh-suggestions-button').click();
                    }
                }
            }
        }
    }

    function enableSubmitButton(){
        if( document.getElementById("index-hashtags-button") != null ){
            document.getElementById("index-hashtags-button").disabled = false;
            document.getElementById("index-hashtags-button").classList.remove("opacity-50");
            document.getElementById("index-hashtags-button").classList.remove("cursor-not-allowed");
        }
        if( document.getElementById("index-collected-accounts-button") != null ){
            document.getElementById("index-collected-accounts-button").disabled = false;
            document.getElementById("index-collected-accounts-button").classList.remove("opacity-50");
            document.getElementById("index-collected-accounts-button").classList.remove("cursor-not-allowed");
        }
    }
    function disableSubmitButton(){
        if( document.getElementById("index-hashtags-button") != null ){
            document.getElementById("index-hashtags-button").disabled = true;
            document.getElementById("index-hashtags-button").classList.add("opacity-50");
            document.getElementById("index-hashtags-button").classList.add("cursor-not-allowed");
        }
        if( document.getElementById("index-collected-accounts-button") != null ){
            document.getElementById("index-collected-accounts-button").disabled = true;
            document.getElementById("index-collected-accounts-button").classList.add("opacity-50");
            document.getElementById("index-collected-accounts-button").classList.add("cursor-not-allowed");
        }
    }
    function enableSuggestedButton(){
        if( document.getElementById("suggested-hashtags-button") != null ){
            document.getElementById("suggested-hashtags-button").disabled = false;
            document.getElementById("suggested-hashtags-button").classList.remove("opacity-50");
            document.getElementById("suggested-hashtags-button").classList.remove("cursor-not-allowed");
            document.getElementById("suggested-hashtags-button").innerText = "Add Hashtags to Collection";
        }
    }
    function disableSuggestedButton(){
        if( document.getElementById("suggested-hashtags-button") != null ){
            document.getElementById("suggested-hashtags-button").disabled = true;
            document.getElementById("suggested-hashtags-button").classList.add("opacity-50");
            document.getElementById("suggested-hashtags-button").classList.add("cursor-not-allowed");
            document.getElementById("suggested-hashtags-button").innerText = "Please Select Hashtags";
        }
    }

    function manageButtons(){
        var innerCheckboxesAreChecked = false;
        var spinners = document.getElementsByClassName('animate-spinner');
        for( var j = 0; j < document.getElementsByClassName('hashtag-checkbox').length; j++ ){
            if( document.getElementsByClassName('hashtag-checkbox')[j].checked ){
                innerCheckboxesAreChecked = true;
            }
        }
        for( var j = 0; j < document.getElementsByClassName('collected_account-checkbox').length; j++ ){
            if( document.getElementsByClassName('collected_account-checkbox')[j].checked ){
                innerCheckboxesAreChecked = true;
            }
        }

        checkboxesareChecked = innerCheckboxesAreChecked;
        /*if( spinners.length > 1 ){
            disableSubmitButton();
            disableSuggestedButton();
            if( document.getElementById("index-hashtags-button") != null ){
                document.getElementById("index-hashtags-button").innerText = "Analyzing hashtags"
            }
        }else */
        
        if( checkboxesareChecked == false ){
            disableSubmitButton();
            disableSuggestedButton();
            //Determine button text based on action selection
            if(document.getElementById("selected_action") != null ){
                if( document.getElementById("selected_action").value == "Select an action..." ){
                    document.getElementById("index-hashtags-button").innerText = "Please Select an Action"
                }else if( document.getElementById("selected_action").value == "Copy hashtags to another collection" ){
                    document.getElementById("index-hashtags-button").innerText = "Select Hashtags to Copy"
                }else if( document.getElementById("selected_action").value == "Move hashtags to another collection" ){
                    document.getElementById("index-hashtags-button").innerText = "Select Hashtags to Move"
                }else if( document.getElementById("selected_action").value == "Analyze selected hashtags" ){
                    document.getElementById("index-hashtags-button").innerText = "Select Hashtags for Analysis"
                }else if( document.getElementById("selected_action").value == "Remove selected hashtags" ){
                    document.getElementById("index-hashtags-button").innerText = "Select Hashtags for Removal"
                }
            }
            if(document.getElementById("selected_collected_account_action") != null ){
                if( document.getElementById("selected_collected_account_action").value == "Select an action..." ){
                    document.getElementById("index-collected-accounts-button").innerText = "Please Select an Action"
                }else if( document.getElementById("selected_collected_account_action").value == "Copy accounts to another collection" ){
                    document.getElementById("index-collected-accounts-button").innerText = "Select Accounts to Copy"
                }else if( document.getElementById("selected_collected_account_action").value == "Move accounts to another collection" ){
                    document.getElementById("index-collected-accounts-button").innerText = "Select Accounts to Move"
                }else if( document.getElementById("selected_collected_account_action").value == "Remove selected accounts" ){
                    document.getElementById("index-collected-accounts-button").innerText = "Select Accounts for Removal"
                }
            }
        }else{

            enableSuggestedButton();
            if(document.getElementById("selected_action") != null){
                //Determine button text based on action selection
                if( document.getElementById("selected_action").value == "Select an action..." ){
                    disableSubmitButton();
                    document.getElementById("index-hashtags-button").innerText = "Please Select an Action"
                }else if( document.getElementById("selected_action").value == "Copy hashtags to another collection" ){
                    if( document.getElementById("category").value == "" ){
                        disableSubmitButton();
                        document.getElementById("index-hashtags-button").innerText = "Please Select a Collection"
                    }else{
                        enableSubmitButton();
                        document.getElementById("index-hashtags-button").innerText = "Copy Selected Hashtags";
                        document.getElementById("index-hashtags-button").removeAttribute('data-confirm');
                    }

                }else if( document.getElementById("selected_action").value == "Move hashtags to another collection" ){
                    if( document.getElementById("category").value == "" ){
                        disableSubmitButton();
                        document.getElementById("index-hashtags-button").innerText = "Please Select a Collection"
                    }else{
                        enableSubmitButton();
                        document.getElementById("index-hashtags-button").innerText = "Move Selected Hashtags";
                        document.getElementById("index-hashtags-button").removeAttribute('data-confirm');
                    }

                }else if( document.getElementById("selected_action").value == "Analyze selected hashtags" ){
                    enableSubmitButton();
                    document.getElementById("index-hashtags-button").innerText = "Analyze Selected Hashtags";
                    document.getElementById("index-hashtags-button").removeAttribute('data-confirm');

                }else if( document.getElementById("selected_action").value == "Remove selected hashtags" ){
                    enableSubmitButton();
                    document.getElementById("index-hashtags-button").innerText = "Remove Selected Hashtags";
                    document.getElementById("index-hashtags-button").setAttribute('data-confirm', 'Are you sure?');
                }
            }
            if(document.getElementById("selected_collected_account_action") != null){
                //Determine button text based on action selection
                if( document.getElementById("selected_collected_account_action").value == "Select an action..." ){
                    disableSubmitButton();
                    document.getElementById("index-collected-accounts-button").innerText = "Please Select an Action"
                }else if( document.getElementById("selected_collected_account_action").value == "Copy accounts to another collection" ){
                    if( document.getElementById("account_collection").value == "" ){
                        disableSubmitButton();
                        document.getElementById("index-collected-accounts-button").innerText = "Please Select a Collection"
                    }else{
                        enableSubmitButton();
                        document.getElementById("index-collected-accounts-button").innerText = "Copy Selected Accounts";
                        document.getElementById("index-collected-accounts-button").removeAttribute('data-confirm');
                    }

                }else if( document.getElementById("selected_collected_account_action").value == "Move accounts to another collection" ){
                    if( document.getElementById("account_collection").value == "" ){
                        disableSubmitButton();
                        document.getElementById("index-collected-accounts-button").innerText = "Please Select a Collection"
                    }else{
                        enableSubmitButton();
                        document.getElementById("index-collected-accounts-button").innerText = "Move Selected Accounts";
                        document.getElementById("index-collected-accounts-button").removeAttribute('data-confirm');
                    }

                }else if( document.getElementById("selected_collected_account_action").value == "Remove selected accounts" ){
                    enableSubmitButton();
                    document.getElementById("index-collected-accounts-button").innerText = "Remove Selected Accounts";
                    document.getElementById("index-collected-accounts-button").setAttribute('data-confirm', 'Are you sure?');
                }
            }
        }
    }  

    function refreshPartial() {

        checkboxes = document.getElementsByClassName('hashtag-checkbox');
        checkboxesareChecked = false;
        var categoryId = document.getElementById('category-identifier');
        var CategoryTitle = document.getElementById("category-identifier");
        
        if( CategoryTitle != null ){
            for( var i = 0; i < pending.length; i++ ){
                if( document.getElementById("category-identifier") != null ){
                    var hashtagId = pending[i].previousElementSibling.getElementsByClassName('hashtag-checkbox')[0].getAttribute('id');
                    if( categoryId != null && hashtagId != null){
                        var hashtagString = "set-hashtag__data-" + hashtagId;
                        if( document.getElementById(hashtagString).classList.contains('hashtag-pending') ){
                            console.log('found pending hashtag');
                            categoryId = document.getElementById('category-identifier');
                            categoryId = categoryId.getAttribute('data-id');
                            var timeToWait = i * 900;
                            var categoryRefreshUrl = "/collections/" + categoryId + "/refresh_data/" + hashtagId;
                            setTimeoutWithTurbo(getCategoryAjax, timeToWait, categoryRefreshUrl);
                        }
                    }
                    var pill = pending[i].getElementsByClassName('pill')[0];
                    if( pill != null){
                        pending[i].classList.remove('hashtag-pending');
                        pending = document.getElementsByClassName('hashtag-pending');
                    }
                }
            }
            
            function toggle(source) {
                var checkboxes = document.querySelectorAll('input[type="checkbox"]');
                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i] != source){
                        checkboxes[i].checked = source.checked;
                    }
                }
                manageButtons();
            }

            document.removeEventListener("input", function(event){});
            var selectAction = document.getElementById("selected_action");
            let suggestedHashtagsButton = document.getElementById('suggested-hashtags-button');
            if ( selectAction != null || suggestedHashtagsButton != null ){
                document.addEventListener("input", function(event){
                    manageButtons();

                    if ( event.target.id == "select-all" ){
                        toggle(event.target);
                    }
                    if ( event.target.id == "selected_action" ){  
                        if( event.target.value == "Copy hashtags to another collection" ){
                            document.getElementById('category').classList.remove('hidden');
                        }else if( event.target.value == "Move hashtags to another collection" ){
                            document.getElementById('category').classList.remove('hidden');
                        }else{
                            document.getElementById('category').classList.add('hidden');
                        }
                    }
                });
            }
            manageButtons(); 
        }
    }

    function refreshAccountCollection() {

        checkboxes = document.getElementsByClassName('collected_account-checkbox');
        checkboxesareChecked = false;
        var accountCollectionId = document.getElementById('account-collection-identifier');
        var AccountCollectionTitle = document.getElementById("account-collection-identifier");
        
        if( AccountCollectionTitle != null ){
            function toggle(source) {
                var checkboxes = document.querySelectorAll('input[type="checkbox"]');
                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i] != source){
                        checkboxes[i].checked = source.checked;
                    }
                }
                manageButtons();
            }

            var selectCollectedAccountAction = document.getElementById("selected_collected_account_action");
            if ( selectCollectedAccountAction != null ){
                document.addEventListener("input", function(event){
                    manageButtons();

                    if ( event.target.id == "select-all" ){
                        toggle(event.target);
                    }
                    if ( event.target.id == "selected_collected_account_action" ){  
                        if( event.target.value == "Copy accounts to another collection" ){
                            document.getElementById('account_collection').classList.remove('hidden');
                        }else if( event.target.value == "Move accounts to another collection" ){
                            document.getElementById('account_collection').classList.remove('hidden');
                        }else{
                            document.getElementById('account_collection').classList.add('hidden');
                        }
                    }
                });
            }
            manageButtons(); 
        }
    }


    var CategoryTitle = document.getElementById("category-identifier");
    if( CategoryTitle != null ){
        refreshPartial();
        if( pending.length != 0 ){
            setIntervalWithTurbo(refreshPartial, pending.length * 901);
        }else{
            setIntervalWithTurbo(refreshPartial, 30 * 901);
        }
        if( document.getElementById('selected_action') != null ){
            if( document.getElementById('selected_action').value == "Copy hashtags to another collection" ){
                document.getElementById('category').classList.remove('hidden');
            }
            if( document.getElementById('selected_action').value == "Move hashtags to another collection" ){
                document.getElementById('category').classList.remove('hidden');
            }
        }
        setIntervalWithTurbo(manageButtons, 1000);
    }

    var AccountCollectionTitle = document.getElementById("account-collection-identifier");
    if( AccountCollectionTitle != null ){
        refreshAccountCollection();
        if( pending.length != 0 ){
            setIntervalWithTurbo(refreshAccountCollection, pending.length * 901);
        }else{
            setIntervalWithTurbo(refreshAccountCollection, 30 * 901);
        }
        if( document.getElementById('selected_collected_account_action') != null ){
            if( document.getElementById('selected_collected_account_action').value == "Copy accounts to another collection" ){
                document.getElementById('account_collection').classList.remove('hidden');
            }
            if( document.getElementById('selected_collected_account_action').value == "Move accounts to another collection" ){
                document.getElementById('account_collection').classList.remove('hidden');
            }
        }
        setIntervalWithTurbo(manageButtons, 1000);
    }
    
    var bullkHashtagsButton = document.getElementById("show-bulk-hashtags-button")
    if( bullkHashtagsButton != null ){
        document.getElementById("show-bulk-hashtags-button").onclick = function(){
            document.getElementById('set-hashtags').classList.add('hidden');
            document.getElementById('bulk-hashtags').classList.remove('hidden');
            document.getElementById("show-bulk-hashtags-button").classList.add('hidden');
        }
    }
    
    var bulkAccountsButton = document.getElementById("show-bulk-accounts-button")
    if( bulkAccountsButton != null ){
        document.getElementById("show-bulk-accounts-button").onclick = function(){
            document.getElementById('collection__collected-accounts').classList.add('hidden');
            document.getElementById('bulk-accounts').classList.remove('hidden');
            document.getElementById("show-bulk-accounts-button").classList.add('hidden');
        }
    }

    if( document.getElementById('category-show-suggested-button') != null ){
        document.getElementById('category-show-suggested-button').onclick = function(){
            document.getElementById("category-show-suggested-button").classList.add("btn--secondary-outline--active");
            document.getElementById("category-show-collection-button").classList.remove("btn--secondary-outline--active");
            document.getElementById("set-hashtags").classList.add("hidden");
            document.getElementById("suggested-hashtags").classList.remove("hidden");
            document.getElementById("suggested-hashtags-form").classList.remove("hidden");
            document.getElementById("refresh-suggestions-button").classList.remove("hidden");

            if( document.getElementsByClassName('info-text--collected').length > 0 ){
                document.getElementsByClassName('info-text--collected')[0].classList.add('hidden');
                document.getElementsByClassName('info-text--suggested')[0].classList.remove('hidden');
            }

            if( bullkHashtagsButton != null ){
                document.getElementById('bulk-hashtags').classList.add('hidden');
                document.getElementById("show-bulk-hashtags-button").classList.remove('hidden');
            }
        }
    }

    if( document.getElementById('category-show-collection-button') != null ){
        document.getElementById('category-show-collection-button').onclick = function(){
            document.getElementById("category-show-suggested-button").classList.remove("btn--secondary-outline--active");
            document.getElementById("category-show-collection-button").classList.add("btn--secondary-outline--active");
            document.getElementById("set-hashtags").classList.remove("hidden");
            document.getElementById("suggested-hashtags").classList.add("hidden");
            document.getElementById("suggested-hashtags-form").classList.add("hidden");
            document.getElementById("refresh-suggestions-button").classList.add("hidden");

            if( document.getElementsByClassName('info-text--collected').length > 0 ){
                document.getElementsByClassName('info-text--collected')[0].classList.remove('hidden');
                document.getElementsByClassName('info-text--suggested')[0].classList.add('hidden');
            }

            if( bullkHashtagsButton != null ){
                document.getElementById('bulk-hashtags').classList.add('hidden');
                document.getElementById("show-bulk-hashtags-button").classList.remove('hidden');
            }
        }
    }

    if( document.getElementById('collection_order') != null ){
        document.addEventListener('input', function (event) {

            // Only run on our select menu
            if (event.target.id !== 'collection_order') return;
            
            let categoryId = document.getElementById('category-identifier');
            categoryId = categoryId.getAttribute('data-id');
            let categoryRefreshUrl = "/collections/" + categoryId + "/order_collection?collection_order=" + event.target.value;
            getCategoryAjax(categoryRefreshUrl);
        
        }, false);        
    }

    if( document.getElementById('account_collection_order') != null ){
        document.addEventListener('input', function (event) {

            // Only run on our select menu
            if (event.target.id !== 'account_collection_order') return;
            
            let accountCollectionId = document.getElementById('account-collection-identifier');
            accountCollectionId = accountCollectionId.getAttribute('data-id');
            let accountCollectionRefreshUrl = "/account_collections/" + accountCollectionId + "/order_account_collection?account_collection_order=" + event.target.value;
            getCategoryAjax(accountCollectionRefreshUrl);
        
        }, false);        
    }

    if( document.getElementById('suggested_order') != null ){
        document.addEventListener('input', function (event) {

            // Only run on our select menu
            if (event.target.id !== 'suggested_order') return;
            
            let categoryId = document.getElementById('category-identifier');
            categoryId = categoryId.getAttribute('data-id');
            let categoryRefreshUrl = "/collections/" + categoryId + "/order_suggested?suggested_order=" + event.target.value;
            getCategoryAjax(categoryRefreshUrl);
        
        }, false);        
    }

});
