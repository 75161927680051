import Rails from '@rails/ujs';

window.addEventListener('turbo:load', function(){
    if( document.getElementById('content-idea-more-like-this-status') != null ){
        if( document.getElementById('content-idea-more-like-this-status').value == "generating" ){

            var moreLikeThisInterval;
            let contentIdeaId = document.getElementById('content-idea-identifier');
            contentIdeaId = contentIdeaId.getAttribute('data-id');
            let moreLikeThisRefreshUrl = "/content_ideas/" + contentIdeaId + "/more_like_this/refresh";

            function setMoreLikeThisIntervalWithTurbo(intervalFunction, milliseconds) {
                moreLikeThisInterval = setInterval(intervalFunction, milliseconds);
                window.addEventListener('turbo:before-cache', function(){
                    clearTimeout(moreLikeThisInterval);
                });
                window.addEventListener('turbo:before-render', function(){
                    clearTimeout(moreLikeThisInterval);
                });
            }

            function refreshMoreLikeThis() {
                setTimeout(function(){
                    Rails.ajax({
                        type: 'POST',
                        url: moreLikeThisRefreshUrl
                    });
                }, 2000, moreLikeThisRefreshUrl);

                if( document.getElementById('content-idea-more-like-this-status') != null ){
                    if( document.getElementById('content-idea-more-like-this-status').value != "generating" ){
                        clearInterval(moreLikeThisInterval);
                    }
                }
            }

            setMoreLikeThisIntervalWithTurbo(refreshMoreLikeThis, 2000);
        }
    }
});