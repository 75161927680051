import Rails from '@rails/ujs';
import confetti from 'canvas-confetti';

export function setIntervalWithTurbo(intervalFunction, milliseconds) {
    var interval = setInterval(intervalFunction, milliseconds);
    window.addEventListener('turbo:before-cache', function(){
        clearTimeout(interval);
    });
    window.addEventListener('turbo:before-render', function(){
        clearTimeout(interval);
    });
}

export function copyToClipboard(){
    let el = document.getElementById('content');
    el.select();
    document.execCommand('copy');
    var flash = document.createElement('div');
    flash.innerHTML='<ul class="flash-wrapper animate flashAnimation"><li class="flash">Hashtags copied. Go slay!</li></ul>';
    document.body.appendChild(flash);

    if (document.activeElement) {
        document.activeElement.blur();
    }

    var copyToClick = document.getElementById("copy-to-click");
    if( copyToClick != null ){
        copyToClick.onclick = function(){
            copyToClipboard();
        }
    }
}

export function copyTagsToClipboard(){
    let checkedTags = document.getElementById('hashtags-index-form').querySelectorAll('input:checked');
    let allSelectedTags = "";

    for( let i = 0; i < checkedTags.length; i++ ){
        let thisTagName = document.querySelector('label[for="'+checkedTags[i].getAttribute('id')+'"]').innerText;
        if( thisTagName != "Select all" ){
            allSelectedTags += thisTagName;
            allSelectedTags += " ";
        }
    }

    let tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = allSelectedTags;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    var flash = document.createElement('div');

    if( allSelectedTags == "" ){
        flash.innerHTML='<ul class="flash-wrapper animate flashAnimation"><li class="flash">Select hashtags to copy.</li></ul>';
    } else {
        flash.innerHTML='<ul class="flash-wrapper animate flashAnimation"><li class="flash">Hashtags copied. Go slay!</li></ul>';
    }

    document.body.innerHTML += flash.innerHTML;

    var copyTags = document.getElementById("copy-tags");
    if( copyTags != null ){
        copyTags.onclick = function(){
            copyTagsToClipboard();
        }
    }
}

export function smoothScrollTo(element) {
    const targetPosition = element.getBoundingClientRect().top + window.pageYOffset;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    const duration = 1000; // duration of the scroll in milliseconds
    let start = null;

    window.requestAnimationFrame(step);

    function step(timestamp) {
    if (!start) start = timestamp;
    const progress = timestamp - start;
    window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration));
    if (progress < duration) window.requestAnimationFrame(step);
    }

    function easeInOutCubic(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t * t + b;
    t -= 2;
    return c / 2 * (t * t * t + 2) + b;
    }
    console.log('smoothScrollTo ran for distance: ' + distance);
}

document.addEventListener(
    'click',
    event => {
      let element = event.target;
      if (element.dataset.disableWith) {
        if( element.classList.contains('skip-disable-js') == false ){
            element.disabled = true;
            element.innerHTML = element.dataset.disableWith;
            let form = element.closest('form');
            if( form != null ){
                if (form.length) {
                    event.preventDefault();
                    setTimeout(() => form.submit(), 300);
                }
            }
        }
      }
    },
    true
);

window.addEventListener('turbo:load', function(){
    const focusableElementsSelector = 'input[type="text"], textarea';
    document.querySelectorAll(focusableElementsSelector).forEach(element => {
        element.addEventListener('focus', () => {
            if (isTouchDevice()) {
                // Delay to allow keyboard to appear
                setTimeout(() => {
                    scrollToElementIfNeeded(element);
                }, 500); // Adjust timeout as needed
            }
        });
    });

    function isTouchDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    }
    
    function scrollToElementIfNeeded(element) {
        if (typeof element.scrollIntoViewIfNeeded === 'function') {
            element.scrollIntoViewIfNeeded(true); // Center align
        } else {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' }); // Fallback
        }
    }
    

    let smoothScrollToElements = document.querySelectorAll('[data-smoothscrollto]');
    if( smoothScrollToElements != null ){
        smoothScrollToElements.forEach(function(smoothScrollToElement){
            smoothScrollToElement.addEventListener('click', function(){
                let scrollToElement = document.querySelector(smoothScrollToElement.dataset.smoothscrollto);
                setTimeout(()=>{smoothScrollTo(scrollToElement);},100);
            });
        });
    }

    let confettiButtons = document.querySelectorAll('.button--confetti');
    if( confettiButtons != null ){
        confettiButtons.forEach(function(confettiButton){
            confettiButton.addEventListener('click', function(){
                setTimeout(() => {
                  window.dispatchEvent(new Event('resize'));
                }, 10);

                confetti({
                    particleCount: 150,
                    spread: 60,
                    origin: {
                        y: 1
                    }
                });
            });
        });
    }

    function getSlayerAjax(slayerRefreshUrl) {
        Rails.ajax({
            type: 'GET',
            url: slayerRefreshUrl
        });
    }

    var slayerWrap = document.getElementById("slayer-text-wrap");
    if( slayerWrap != null ){
        setIntervalWithTurbo(function(){
            if( document.getElementById('content').value == "Still optimizing..." || document.getElementById('content').value == "Fetching hashtags..." || document.getElementById('content').value == "Calculating for your account..."  || document.getElementById('content').value == "Please wait..."){
                let slayerRefreshUrl = "/refresh_slayer";
                getSlayerAjax(slayerRefreshUrl);
            }

            let closeSlayerSettings = document.getElementById("close-slayer-settings");
            let displaySlayerSettings = document.getElementById("display-slayer-settings");
            if( displaySlayerSettings != null ) {
                displaySlayerSettings.onclick = function(){
                    slideToggle(document.getElementById("slayer-settings"), 200);
                    displaySlayerSettings.classList.add('hidden');

                    if( closeSlayerSettings != null ) {
                        closeSlayerSettings.classList.remove('hidden');
                    }
                }
            }

            if( closeSlayerSettings != null ) {
                closeSlayerSettings.onclick = function(){
                    slideToggle(document.getElementById("slayer-settings"), 200);
                    closeSlayerSettings.classList.add('hidden');

                    if( displaySlayerSettings != null ) {
                        displaySlayerSettings.classList.remove('hidden');
                    }
                }
            }

        }, 2000);
    }

    /* SLIDE UP */
    let slideUp = (target, duration=500) => {

        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = duration + 'ms';
        target.style.boxSizing = 'border-box';
        target.style.height = target.offsetHeight + 'px';
        target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        window.setTimeout( () => {
              target.style.display = 'none';
              target.style.removeProperty('height');
              target.style.removeProperty('padding-top');
              target.style.removeProperty('padding-bottom');
              target.style.removeProperty('margin-top');
              target.style.removeProperty('margin-bottom');
              target.style.removeProperty('overflow');
              target.style.removeProperty('transition-duration');
              target.style.removeProperty('transition-property');
              //alert("!");
        }, duration);
    }

    /* SLIDE DOWN */
    let slideDown = (target, duration=500) => {

        target.style.removeProperty('display');
        let display = window.getComputedStyle(target).display;
        if (display === 'none') display = 'block';
        target.style.display = display;
        let height = target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        target.offsetHeight;
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = "height, margin, padding";
        target.style.transitionDuration = duration + 'ms';
        target.style.height = height + 'px';
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        window.setTimeout( () => {
          target.style.removeProperty('height');
          target.style.removeProperty('overflow');
          target.style.removeProperty('transition-duration');
          target.style.removeProperty('transition-property');
        }, duration);
    }

    /* TOOGLE */
    var slideToggle = (target, duration = 500) => {
        if (window.getComputedStyle(target).display === 'none') {
          return slideDown(target, duration);
        } else {
          return slideUp(target, duration);
        }
    }

    var copyToClick = document.getElementById("copy-to-click");
    if( copyToClick != null ){
        copyToClick.onclick = function(){
            copyToClipboard();
        }
    }

    var copyTags = document.getElementById("copy-tags");
    if( copyTags != null ){
        copyTags.onclick = function(){
            copyTagsToClipboard();
        }
    }

    var AIHSViewHashtags = document.getElementById("aihs-view-hashtags");
    var AIHSViewCaption = document.getElementById("aihs-view-caption");
    var AIHSViewHashtagsOnboardingInfo = document.getElementById("onboarding-info__hashtags-view");
    var AIHSViewCaptionOnboardingInfo = document.getElementById("onboarding-info__caption-view");
    var AIHSClearCaptionWrap = document.getElementById("aihs-clear-caption-wrap");
    var AIHSClearCaption = document.getElementById("aihs-clear-caption");
    var AIHSCaptionViewButtons = document.getElementById("aihs-caption-view-buttons");
    var AIHSHashtagViewButtons = document.getElementById("aihs-hashtag-view-buttons");

    if( AIHSViewHashtags != null && AIHSViewCaption != null ){
        AIHSViewCaption.onclick = function(){
            let slayerTextWrap = document.getElementById("slayer-text-wrap");
            let slayerTextWrapCaption = slayerTextWrap.querySelector("#caption");
            let slayerTextWrapContent = slayerTextWrap.querySelector("#content");
            slayerTextWrapCaption.classList.remove('hidden');
            slayerTextWrapContent.classList.add('hidden');

            AIHSViewCaption.classList.remove('aihstab--inactive');
            AIHSViewCaption.classList.add('aihstab--active');

            AIHSViewHashtags.classList.add('aihstab--inactive');
            AIHSViewHashtags.classList.remove('aihstab--active');

            AIHSClearCaptionWrap.classList.remove('hidden');
            AIHSCaptionViewButtons.classList.remove('hidden');
            AIHSHashtagViewButtons.classList.add('hidden');

            if( AIHSViewHashtagsOnboardingInfo && AIHSViewCaptionOnboardingInfo){
                AIHSViewHashtagsOnboardingInfo.classList.add('hidden');
                AIHSViewCaptionOnboardingInfo.classList.remove('hidden');
            }
        }

        AIHSViewHashtags.onclick = function(){
            let slayerTextWrap = document.getElementById("slayer-text-wrap");
            let slayerTextWrapCaption = slayerTextWrap.querySelector("#caption");
            let slayerTextWrapContent = slayerTextWrap.querySelector("#content");
            slayerTextWrapCaption.classList.add('hidden');
            slayerTextWrapContent.classList.remove('hidden');

            AIHSViewHashtags.classList.remove('aihstab--inactive');
            AIHSViewCaption.classList.add('aihstab--inactive');

            AIHSViewCaption.classList.remove('aihstab--active');
            AIHSViewHashtags.classList.add('aihstab--active');

            AIHSClearCaptionWrap.classList.add('hidden');
            AIHSCaptionViewButtons.classList.add('hidden');
            AIHSHashtagViewButtons.classList.remove('hidden');

            if( AIHSViewHashtagsOnboardingInfo && AIHSViewCaptionOnboardingInfo ){
                AIHSViewHashtagsOnboardingInfo.classList.remove('hidden');
                AIHSViewCaptionOnboardingInfo.classList.add('hidden');
            }
        }

        AIHSClearCaption.onclick = function(){ 
            let slayerTextWrap = document.getElementById("slayer-text-wrap");
            let slayerTextWrapCaption = slayerTextWrap.querySelector("#caption");
            slayerTextWrapCaption.value = "";
        }

        if( document.getElementById('content').value == "Fetching hashtags..." ) {
            AIHSViewHashtags.click();
            var AIHSPrettyLoader = document.getElementById("aihs-pretty-loader");
            var AIHSPrettyLoaderText = AIHSPrettyLoader.querySelector("#aihs-pl-text");
            let slayerTextWrap = document.getElementById("slayer-text-wrap");
            AIHSPrettyLoader.style.display = "flex";
            AIHSPrettyLoader.style.height = slayerTextWrap.offsetHeight + "px";
            AIHSPrettyLoader.style.marginTop = "calc(-" + slayerTextWrap.offsetHeight + "px - 0.5rem)";
            var loaderIndex = 1;

            setIntervalWithTurbo(function(){
                if( document.getElementById('content').value == "Fetching hashtags..." ){
                    let slayerTextWrap = document.getElementById("slayer-text-wrap");
                    AIHSPrettyLoader.style.display = "flex";
                    AIHSPrettyLoader.style.height = slayerTextWrap.offsetHeight + "px";
                    AIHSPrettyLoader.style.marginTop = "calc(-" + slayerTextWrap.offsetHeight + "px - 0.5rem)";
                    AIHSHashtagViewButtons.classList.add('hidden');
                    
                    switch (loaderIndex) {
                        case 1:
                            AIHSPrettyLoaderText.innerHTML = "Submitting caption...";
                            break;
                        case 2:
                            AIHSPrettyLoaderText.innerHTML = "Retrieving caption...";
                            break;
                        case 3:
                            AIHSPrettyLoaderText.innerHTML = "Connecting AI...";
                            break;
                        case 4:
                            AIHSPrettyLoaderText.innerHTML = "Reading caption...";
                            break;
                        case 5:
                            AIHSPrettyLoaderText.innerHTML = "Analyzing caption...";
                            break;
                        case 6:
                            AIHSPrettyLoaderText.innerHTML = "Reviewing best practices...";
                            break;
                        case 7:
                            AIHSPrettyLoaderText.innerHTML = "Identifying topics...";
                            break;
                        case 8:
                            AIHSPrettyLoaderText.innerHTML = "Identifying keywords...";
                            break;
                        case 9:
                            AIHSPrettyLoaderText.innerHTML = "Identifying audience...";
                            break;
                        case 10:
                            AIHSPrettyLoaderText.innerHTML = "Connecting to database...";
                            break;
                        case 11:
                            AIHSPrettyLoaderText.innerHTML = "Searching hashtags...";
                            break;
                        case 12:
                            AIHSPrettyLoaderText.innerHTML = "Comparing hashtags...";
                            break;
                        case 13:
                            AIHSPrettyLoaderText.innerHTML = "Suggesting hashtags...";
                            break;
                        case 14:
                            AIHSPrettyLoaderText.innerHTML = "Retrieving suggestions...";
                            break;
                        case 15:
                            AIHSPrettyLoaderText.innerHTML = "Revising suggestions...";
                            break;
                        case 16:
                            AIHSPrettyLoaderText.innerHTML = "Accepting suggestions...";
                            break;
                        case 17:
                            AIHSPrettyLoaderText.innerHTML = "Ordering hashtags...";
                            break;
                        case 18:
                            AIHSPrettyLoaderText.innerHTML = "Filtering hashtags...";
                            break;
                        case 19:
                            AIHSPrettyLoaderText.innerHTML = "Optimizing hashtags...";
                            break;
                        case 20:
                            AIHSPrettyLoaderText.innerHTML = "Shuffling hashtags...";
                            break;
                        case 21:
                            AIHSPrettyLoaderText.innerHTML = "Requesting optimized hashtags...";
                            break;
                        case 22:
                            AIHSPrettyLoaderText.innerHTML = "Sending optimized hashtags...";
                            break;
                        case 23:
                            AIHSPrettyLoaderText.innerHTML = "Retrieving optimized hashtags...";
                            break;
                        default:
                            AIHSPrettyLoaderText.innerHTML = "Fetching hashtags...";
                    }
                        
                    loaderIndex++;

                }else{
                    //if display does not equal none
                    if( AIHSPrettyLoader.style.display != "none" ){
                        AIHSPrettyLoader.style.display = "none";
                        let scrollPosition = window.scrollY;
                        let contentTextArea = document.getElementById('content');
                        //expand height of textarea to fit content
                        contentTextArea.style.height = "auto";
                        contentTextArea.style.transition = "height 400ms ease-in-out";
                        contentTextArea.style.height = contentTextArea.scrollHeight + "px";
                        window.scrollTo(0, scrollPosition);
                        AIHSHashtagViewButtons.classList.remove('hidden');

                        if( document.getElementById('onboarding_counter').value == "6" || document.getElementById('onboarding_counter').value == "8" ){
                            if( document.getElementById('flow_onboarding_user').value == "true" ){
                                document.getElementById('tooltip-copy-hashtags').classList.remove('hidden');
                                smoothScrollTo(document.querySelector('#tooltip-copy-hashtags'));
                            }
                        }
                    }
                }
            }, 1600);
        }
    }

    var slider = document.getElementById("slayer-size");
    var output = document.getElementById("size-value");
    if( slider != null ) {
        output.innerHTML = slider.value + " <span class='text-gray-600 text-sm'>#'s</span>"; // Display the default slider value
      
        // Update the current slider value (each time you drag the slider handle)
        slider.oninput = function() {
            output.innerHTML = this.value + " <span class='text-gray-600 text-sm'>#'s</span>";
            setMinimumSuggestions();
        } 
    }

    var setMinimumSuggestions = function(){
        let selectedOptimization = optimizationMode.value;
        let selectedOptimizationEasiestPercentage = parseFloat(document.querySelector('[data-mode="'+ selectedOptimization +'"]').dataset.easiest);
        let selectedOptimizationEasyPercentage = parseFloat(document.querySelector('[data-mode="'+ selectedOptimization +'"]').dataset.easy);
        let selectedOptimizationNormalPercentage = parseFloat(document.querySelector('[data-mode="'+ selectedOptimization +'"]').dataset.normal);
        let selectedOptimizationHardPercentage = parseFloat(document.querySelector('[data-mode="'+ selectedOptimization +'"]').dataset.hard);

        let setSize = document.getElementById('slayer-size').value;
        let setHealth = document.getElementById('set-health');

        let selectedEasiest = 0;
        let selectedEasy = 0;
        let selectedNormal = 0;
        let selectedHard = 0;

        let collectionToggles = document.getElementsByClassName('tgl--collection');
        if( collectionToggles != null ){
            for( let i = 0; i < collectionToggles.length; i++ ){
                if( collectionToggles[i].checked ) {
                    selectedEasiest += parseInt(collectionToggles[i].dataset.easiest);
                    selectedEasy += parseInt(collectionToggles[i].dataset.easy);
                    selectedNormal += parseInt(collectionToggles[i].dataset.normal);
                    selectedHard += parseInt(collectionToggles[i].dataset.hard);
                }
            }
        }

        let selectedOptimizationEasiestMinimum = Math.floor(setSize * selectedOptimizationEasiestPercentage) - selectedEasiest;
        let selectedOptimizationEasyMinimum = Math.floor(setSize * selectedOptimizationEasyPercentage) - selectedEasy;
        let selectedOptimizationNormalMinimum = Math.ceil(setSize * selectedOptimizationNormalPercentage) - selectedNormal;
        let selectedOptimizationHardMinimum = Math.ceil(setSize * selectedOptimizationHardPercentage) - selectedHard;

        let setMinimumEasiest = document.getElementById('set-min-easiest');
        if( setMinimumEasiest != null ) {
            setMinimumEasiest.innerText = selectedOptimizationEasiestMinimum;
            if( selectedOptimizationEasiestMinimum <= 0 ){
                setMinimumEasiest.parentNode.parentNode.classList.add('hidden');
            }else{
                setMinimumEasiest.parentNode.parentNode.classList.remove('hidden');
            }
        }
        let setMinimumEasy = document.getElementById('set-min-easy');
        if( setMinimumEasy != null ) {
            setMinimumEasy.innerText = selectedOptimizationEasyMinimum;
            if( selectedOptimizationEasyMinimum <= 0 ){
                setMinimumEasy.parentNode.parentNode.classList.add('hidden');
            }else{
                setMinimumEasy.parentNode.parentNode.classList.remove('hidden');
            }
        }
        let setMinimumNormal = document.getElementById('set-min-normal');
        if( setMinimumNormal != null ) {
            setMinimumNormal.innerText = selectedOptimizationNormalMinimum;
            if( selectedOptimizationNormalMinimum <= 0 ){
                setMinimumNormal.parentNode.parentNode.classList.add('hidden');
            }else{
                setMinimumNormal.parentNode.parentNode.classList.remove('hidden');
            }
        }
        let setMinimumHard = document.getElementById('set-min-hard');
        if( setMinimumHard != null ) {
            setMinimumHard.innerText = selectedOptimizationHardMinimum;
            if( selectedOptimizationHardMinimum <= 0 ){
                setMinimumHard.parentNode.parentNode.classList.add('hidden');
            }else{
                setMinimumHard.parentNode.parentNode.classList.remove('hidden');
            }
        }

        if( setHealth != null ) {
            if( selectedOptimizationEasiestMinimum < 0 ){
                selectedOptimizationEasiestMinimum = 0;
            }
            if( selectedOptimizationEasyMinimum < 0 ){
                selectedOptimizationEasyMinimum = 0;
            }
            if( selectedOptimizationNormalMinimum < 0 ){
                selectedOptimizationNormalMinimum = 0;
            }
            if( selectedOptimizationHardMinimum < 0 ){
                selectedOptimizationHardMinimum = 0;
            }

            let totalMinimumAchieved = setSize - (selectedOptimizationEasiestMinimum + selectedOptimizationEasyMinimum + selectedOptimizationNormalMinimum + selectedOptimizationHardMinimum);
            let setHealthPercent = Math.floor((totalMinimumAchieved / setSize) * 100);
            if( setHealthPercent > 100){ 
                setHealthPercent = 100;
            }

            let selectedCollections = 0;
            let collectionToggles = document.getElementsByClassName('tgl--collection');
            if( collectionToggles != null ){
                for( let i = 0; i < collectionToggles.length; i++ ){
                    if( collectionToggles[i].checked ) {
                        selectedCollections += 1
                    }
                }
            }

            if( selectedCollections == 0 ){
                setHealthPercent = 0;
            }

            setHealth.innerText = setHealthPercent;
            
            setHealth.parentNode.classList.remove('pill--banned', 'pill--max', 'pill--high', 'pill--mid', 'pill--low')
            if( setHealthPercent < 10){
                setHealth.parentNode.classList.add('pill--banned');
            }else if( setHealthPercent >= 10 && setHealthPercent < 50){
                setHealth.parentNode.classList.add('pill--max');
            }else if( setHealthPercent >= 50 && setHealthPercent < 70){
                setHealth.parentNode.classList.add('pill--high');
            }else if( setHealthPercent >= 70 && setHealthPercent < 85 ){
                setHealth.parentNode.classList.add('pill--low');
            }else if( setHealthPercent >= 85 ) {
                setHealth.parentNode.classList.add('pill--mid');
            }

            if(setHealthPercent == 100) {
                document.getElementById('set-health-description').classList.add('hidden');
                document.getElementById('set-health-congrats').classList.remove('hidden');
            }else{
                document.getElementById('set-health-description').classList.remove('hidden');
                document.getElementById('set-health-congrats').classList.add('hidden');
            } 


            let displaySetHealth = document.getElementById("display-set-health");
            if( displaySetHealth != null ) {
                displaySetHealth.onclick = function(){
                    document.getElementById('set-health-wrapper').classList.toggle('opened');
                }
            }
        }
        
    }

    var optimizationMode = document.getElementById("optimization_mode");
    if( optimizationMode != null ) {
        function displayOptimizationDescription(){
            let selectedOptimization = optimizationMode.value;
            let modeDescriptions = document.querySelectorAll('.optimization-mode-desc');

            for( let i = 0; i < modeDescriptions.length; i++ ) {
                modeDescriptions[i].classList.add('hidden');
            }

            document.querySelector('[data-mode="'+ selectedOptimization +'"]').classList.remove("hidden");
        } 

        document.addEventListener("input", function(event){
            if ( event.target.id == "optimization_mode" ){
                displayOptimizationDescription();
                setMinimumSuggestions();
            }
            if( event.target.classList.contains('tgl') ){
                setMinimumSuggestions();
            }
        });
        displayOptimizationDescription();
        setMinimumSuggestions();
    }

    var capilizationMode = document.getElementById("capitalization");
    if( capilizationMode != null ) {
        function displayCapilizationDescription(){
            let selectedCapilization = capilizationMode.value;
            let modeDescriptions = document.querySelectorAll('.capitalization-mode-desc');

            for( let i = 0; i < modeDescriptions.length; i++ ) {
                modeDescriptions[i].classList.add('hidden');
            }

            document.querySelector('[data-mode="'+ selectedCapilization +'"]').classList.remove("hidden");
        } 

        document.addEventListener("input", function(event){
            if ( event.target.id == "capitalization" ){
                displayCapilizationDescription();
            }
        });
        displayCapilizationDescription();
    }

    let closeSlayerSettings = document.getElementById("close-slayer-settings");
    let displaySlayerSettings = document.getElementById("display-slayer-settings");
    if( displaySlayerSettings != null ) {
        displaySlayerSettings.onclick = function(){
            slideToggle(document.getElementById("slayer-settings"), 200);
            displaySlayerSettings.classList.add('hidden');

            if( closeSlayerSettings != null ) {
                closeSlayerSettings.classList.remove('hidden');
            }
        }
    }

    if( closeSlayerSettings != null ) {
        closeSlayerSettings.onclick = function(){
            slideToggle(document.getElementById("slayer-settings"), 200);
            closeSlayerSettings.classList.add('hidden');

            if( displaySlayerSettings != null ) {
                displaySlayerSettings.classList.remove('hidden');
            }
        }
    }

});