import Rails from '@rails/ujs';


function setIntervalWithTurbo(intervalFunction, milliseconds) {
    var interval = setInterval(intervalFunction, milliseconds);
    window.addEventListener('turbo:before-cache', function(){
        clearTimeout(interval);
    });
    window.addEventListener('turbo:before-render', function(){
        clearTimeout(interval);
    });
}

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}


window.addEventListener('turbo:load', function(){
    
    var planSlider = document.getElementById("plan-quantity");
    var planOutput = document.getElementById("quantity-value");
    if( planSlider != null ) {
        let planPrice = document.getElementById('calculated-plan-price');
        //check if planPrice hasclass current-account__grandfathered_at_9
        if( planPrice.classList.contains('current-account__grandfathered_at_9') ){
            var planBasePrice = 9;
            var planAdditionalPrice = 7;
        } else {
            var planBasePrice = 12;
            var planAdditionalPrice = 9;
        }

        if( document.getElementById('plan-button-wrapper') ){
            let planButton = document.getElementById('plan-button-wrapper').getElementsByTagName('a')[0];
            if( planSlider.value > 1 ){
                planPrice.innerText = planBasePrice + (planAdditionalPrice * (planSlider.value - 1) );
                planOutput.innerHTML = planSlider.value + " <span class='text-gray-600 text-sm'>Instagram Accounts</span>"; // Display the default slider value
            }else{
                planPrice.innerText = planBasePrice;
                planOutput.innerHTML = planSlider.value + " <span class='text-gray-600 text-sm'>Instagram Account</span>"; // Display the default slider value
            }

            let planButtonUrl = new URL( planButton.href );
            let planButtonParams = planButtonUrl.searchParams;
            planButtonParams.delete('quantity');
            planButtonParams.set('quantity', planSlider.value);
            planButtonUrl.search = planButtonParams.toString();
            planButton.href = planButtonUrl.toString();
        }
       
        document.querySelector('#minus').onclick = function(){
            if( planSlider.value > 1 ){
                let step = parseInt(planSlider.getAttribute('step'), 10);
                let currentSliderValue = parseInt(planSlider.value, 10);
                var newStepValue = currentSliderValue + step;

                newStepValue = currentSliderValue - step;
                planSlider.value = newStepValue;
                planSlider.oninput();
            }
        }
       
        document.querySelector('#plus').onclick = function(){
            if( planSlider.value < 32  ){
                let step = parseInt(planSlider.getAttribute('step'), 10);
                let currentSliderValue = parseInt(planSlider.value, 10);
                var newStepValue = currentSliderValue + step;

                newStepValue = currentSliderValue + step;
                planSlider.value = newStepValue;
                planSlider.oninput();
            }
        }

        // Update the current slider value (each time you drag the slider handle)
        planSlider.oninput = function() {
            if( planSlider.value > 1 ){
                planPrice.innerText = planBasePrice + (planAdditionalPrice * (planSlider.value - 1) );
                planOutput.innerHTML = this.value + " <span class='text-gray-600 text-sm'>Instagram Accounts</span>";
            }else{
                planPrice.innerText = planBasePrice;
                planOutput.innerHTML = this.value + " <span class='text-gray-600 text-sm'>Instagram Account</span>";
            }


            if( document.getElementById('plan-button-wrapper') ){
                let planButton = document.getElementById('plan-button-wrapper').getElementsByTagName('a')[0];
                let planButtonUrl = new URL( planButton.href );
                let planButtonParams = planButtonUrl.searchParams;
                planButtonParams.delete('quantity');
                planButtonParams.set('quantity', planSlider.value);
                planButtonUrl.search = planButtonParams.toString();
                planButton.href = planButtonUrl.toString();
            }
        } 
    }

    let billingPrice = document.getElementById("calculated-billing-price");
    if( billingPrice != null ){
        if( billingPrice.classList.contains('current-account__grandfathered_at_9') ){
            var planBasePrice = 9;
            var planAdditionalPrice = 7;
        } else {
            var planBasePrice = 12;
            var planAdditionalPrice = 9;
        }

        let quantity = getUrlVars()['quantity'];
        if( quantity > 1 ){
            billingPrice.innerText = planBasePrice + (planAdditionalPrice * (quantity - 1) );
        }else{
            billingPrice.innerText = planBasePrice;
        }
    }


});
