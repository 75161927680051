import Rails from '@rails/ujs';

window.addEventListener('turbo:load', function(){
    if( document.getElementById('content-ideas-count') != null ){
        if( parseInt(document.getElementById('content-ideas-count').value) < 5 ){

            var refreshInterval;
            let refreshUrl = "/content_ideas/refresh";

            function setRefreshIntervalWithTurbo(intervalFunction, milliseconds) {
                refreshInterval = setInterval(intervalFunction, milliseconds);
                window.addEventListener('turbo:before-cache', function(){
                    clearTimeout(refreshInterval);
                });
                window.addEventListener('turbo:before-render', function(){
                    clearTimeout(refreshInterval);
                });
            }

            function refreshContentIdeas() {
                setTimeout(function(){
                    Rails.ajax({
                        type: 'POST',
                        url: refreshUrl
                    });
                }, 2000, refreshUrl);

                if( document.getElementById('content-ideas-count') != null ){
                    if( parseInt(document.getElementById('content-ideas-count').value) > 5){
                        clearInterval(refreshInterval);
                    }
                }
            }

            setRefreshIntervalWithTurbo(refreshContentIdeas, 2000);
        }
    }
});