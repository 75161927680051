import Rails from '@rails/ujs';

export function setIntervalWithTurbo(intervalFunction, milliseconds) {
    var interval = setInterval(intervalFunction, milliseconds);
    window.addEventListener('turbo:before-cache', function(){
        clearTimeout(interval);
    });
    window.addEventListener('turbo:before-render', function(){
        clearTimeout(interval);
    });
}

window.addEventListener('turbo:load', function(){


    function refreshHandlers(){
        var categorySelect = document.getElementById('category');

        if(categorySelect != null){
            categorySelect.addEventListener("change", function() {
                if(categorySelect.value == 0)
                {
                    document.getElementById('competitor-search__new-collection__button').click();
                }
            });
        }
        if( document.querySelector("#new-collection-ajax-submit") != null ){
            document.querySelector("#new-collection-ajax-submit").addEventListener("click", function(e){
                document.getElementById('close-new-collection-modal').click();
            });
        }

        let accountCollectionSelect = document.getElementById('account_collection');

        if(accountCollectionSelect != null){
            accountCollectionSelect.addEventListener("change", function() {
                if(accountCollectionSelect.value == 0)
                {
                    document.getElementById('account-collection__new-account-collection__button').click();
                }
            });
        }
        if( document.querySelector("#new-account-collection-ajax-submit") != null ){
            document.querySelector("#new-account-collection-ajax-submit").addEventListener("click", function(e){
                document.getElementById('close-new-account-collection-modal').click();
            });
        }

        let displayCollectCompetitor = document.getElementById("display-collect-competitor");
        if( displayCollectCompetitor != null ) {
            displayCollectCompetitor.onclick = function(){
                document.getElementById('collect-competitor-wrapper').classList.toggle('opened');
            }
        }
    }
    setIntervalWithTurbo(refreshHandlers, 2000);

    
    function refreshResults() {
        var competitorPending = document.getElementById('competitor-pending');
        var searchedCompetitor = document.getElementById('searched-competitor');
        var retrievedCompetitorID = document.getElementById('retrieved_competitor_id');
        if( competitorPending != null){
        var searchRefreshUrl = "/refresh_competitor/" + retrievedCompetitorID.value;
        Rails.ajax({
            type: 'GET',
            url: searchRefreshUrl
        });
        }else{
            if( searchedCompetitor != null ){
                document.getElementById('wait-message').classList="hidden";
            }
        }
    }
    setIntervalWithTurbo(refreshResults, 2000);


});
