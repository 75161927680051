/* eslint no-console:0 */

import "@hotwired/turbo-rails"
import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import "./alpine"
import "./controllers"
import "./refreshers"
//import "./channels"
import ApexCharts from 'apexcharts'
//import "./stylesheets/application.scss"

Rails.start()
Turbo.start()
ActiveStorage.start()
window.ApexCharts = ApexCharts
//import "controllers"
//import "channels"

// Images (jpg, jpeg, png) are imported  here.
// This will copy all static images under `frontend/images` to the output
// folder and reference them with the image_pack_tag helper in views
// (e.g <%= image_pack_tag 'hero.png' %>) or the `imagePath` JavaScript helper
// below.


