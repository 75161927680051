(function() {
  document.addEventListener('click', function(e) {
    if (e.target.hasAttribute('data-link-to-remove-field')) {
      e.preventDefault();
      var input = e.target.previousElementSibling;
      input.value = '1';
      var target = e.target.getAttribute('data-link-to-remove-field');
      var field = e.target.closest(target);
      field.style.display = 'none';
    } else if (e.target.hasAttribute('data-link-to-add-field')) {
      e.preventDefault();
      var fields_html, regexp, time;
      time = new Date().getTime();
      regexp = new RegExp(e.target.getAttribute('data-link-to-add-field-id'), 'g');
      fields_html = e.target.getAttribute('data-link-to-add-field').replace(regexp, time);
      e.target.insertAdjacentHTML('beforebegin', fields_html);
    }
  });
}).call(this);