import Flickity from 'flickity';

function setIntervalWithTurbo(intervalFunction, milliseconds) {
    var interval = setInterval(intervalFunction, milliseconds);
    window.addEventListener('turbo:before-cache', function(){
        clearTimeout(interval);
    });
    window.addEventListener('turbo:before-render', function(){
        clearTimeout(interval);
    });
}

window.addEventListener('ajaxrefreshed', eventHandler, false);
window.addEventListener('turbo:load', eventHandler, false);
window.addEventListener('load', eventHandler, false); 

function eventHandler(event) {
    if( document.querySelector('.caption__carousel') != null ){ 
        var flkty = new Flickity( '.caption__carousel', {
            draggable: true, 
            pageDots: true, 
            prevNextButtons: false, 
            contain: true, 
            wrapAround: false, 
            autoplay: false, 
            adaptiveHeight: true,
            selectedAttraction: 0.2,
            friction: 0.8
        });
    }     

    function getAjax(refreshUrl) {
        Rails.ajax({
            type: 'GET',
            url: refreshUrl
        });
    }

    let siblingExpanders = document.getElementsByClassName("toggle-expander__trigger");
    if( siblingExpanders != null ) {
        for (let i = 0; i < siblingExpanders.length; i++) {
            siblingExpanders[i].onclick = function(){
                this.parentElement.classList.toggle('opened');

                if( this.parentElement.classList.contains('opened') ){
                    this.querySelector('span').innerText = this.querySelector('span').dataset.openText;
                }else{
                    this.querySelector('span').innerText = this.querySelector('span').dataset.closeText;
                }
            }
        }
    }

    if( document.getElementById('caption_collection_order') != null ){
        document.addEventListener('input', function (event) {
            // Only run on our select menu
            if (event.target.id !== 'caption_collection_order') return;
            
            let captionCollectionId = document.getElementById('caption-collection-identifier');
            captionCollectionId = captionCollectionId.getAttribute('data-id');
            let captionCollectionRefreshUrl = "/caption_collections/" + captionCollectionId + "/order_caption_collection?caption_collection_order=" + event.target.value;
            getAjax(captionCollectionRefreshUrl);
        
        }, false);        
    }

    function refreshHandlers(){
        let captionCollectionSelect = document.getElementById('caption_collection');

        if(captionCollectionSelect != null){
            captionCollectionSelect.addEventListener("change", function() {
                if(captionCollectionSelect.value == 0)
                {
                    document.getElementById('caption-collection__new-caption-collection__button').click();
                }
            });
        }
        if( document.querySelector("#new-caption-collection-ajax-submit") != null ){
            document.querySelector("#new-caption-collection-ajax-submit").addEventListener("click", function(e){
                document.getElementById('close-new-caption-collection-modal').click();
            });
        }
    }
    setIntervalWithTurbo(refreshHandlers, 2000);
}