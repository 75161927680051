import Rails from '@rails/ujs';

export function setIntervalWithTurbo(intervalFunction, milliseconds) {
  var interval = setInterval(intervalFunction, milliseconds);
  window.addEventListener('turbo:before-cache', function(){
      clearTimeout(interval);
  });
  window.addEventListener('turbo:before-render', function(){
      clearTimeout(interval);
  });
}

document.addEventListener('click', function (event) {

	// If the clicked element doesn't have the right selector, bail
	if (!event.target.matches('#search-submit')) return;

    document.getElementById('wait-message').classList="";
    document.getElementById('search-submit').setAttribute('data-clicked','true');

}, false);

window.addEventListener('turbo:load', function(){

  var checkboxes = document.getElementsByClassName('hashtag-checkbox');
  var checkboxesareChecked = false;

  function toggle(source) {
      var checkboxes = document.querySelectorAll('input[type="checkbox"]');
      for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i] != source)
              checkboxes[i].checked = source.checked;
      }
      var innerCheckboxesAreChecked = false;
      var spinners = document.getElementsByClassName('animate-spinner');
      for( var j = 0; j < document.getElementsByClassName('hashtag-checkbox').length; j++ ){
          if( document.getElementsByClassName('hashtag-checkbox')[j].checked ){
              innerCheckboxesAreChecked = true;
          }
      }
      checkboxesareChecked = innerCheckboxesAreChecked;
      /*if( spinners.length > 1 ){
          if( document.getElementById("index-hashtags-button") != null ){
            document.getElementById("index-hashtags-button").disabled = true;
            document.getElementById("index-hashtags-button").classList.add("opacity-50");
            document.getElementById("index-hashtags-button").classList.add("cursor-not-allowed");
            document.getElementById("index-hashtags-button").innerText = "Analyzing hashtags";
          }
      }else*/ 
      if( checkboxesareChecked == false ){
        if( document.getElementById("index-hashtags-button") != null ){
          document.getElementById("index-hashtags-button").disabled = true;
          document.getElementById("index-hashtags-button").classList.add("opacity-50");
          document.getElementById("index-hashtags-button").classList.add("cursor-not-allowed");
          document.getElementById("index-hashtags-button").innerText = "Select Hashtags for Analysis";
        }
      }else{
        if( document.getElementById("index-hashtags-button") != null ){
          document.getElementById("index-hashtags-button").disabled = false;
          document.getElementById("index-hashtags-button").classList.remove("opacity-50");
          document.getElementById("index-hashtags-button").classList.remove("cursor-not-allowed");
          document.getElementById("index-hashtags-button").innerText = "Analyze Selected Hashtags";
        }
      }
  }
  
  function refreshResults() {
      console.log('refreshing search');
      let retrievedHashtagID = document.getElementById('retrieved_hashtag_id');
      if( document.getElementById('hashtag-pending') != null){
        let searchRefreshUrl = "/refresh_search/" + retrievedHashtagID.value;
        Rails.ajax({
          type: 'GET',
          url: searchRefreshUrl
        });
      }

      setTimeout(function(){
        if( document.getElementById('hashtag-pending') != null){
          console.log('restarting refresh search');
          refreshResults();
        }
      },3000);
  }
  //setIntervalWithTurbo(refreshResults, 2000);
  //refreshResults();

  function refreshSearchResultCount(){
    let actualRelatedCount = document.getElementById('actual-related-count');
    if( actualRelatedCount != null ){
      document.getElementById('related-found-count').innerHTML = actualRelatedCount.dataset.found;

      if( parseInt(actualRelatedCount.dataset.found) > 10 ){
        document.getElementById('hashtag-found-count-hint').classList.add("hidden");
      }else{
        document.getElementById('hashtag-found-count-hint').classList.remove("hidden");
      }
    }

    let filterAverageLikes = document.getElementById('filter-average-likes');
    let filterAverageLikesDetails = document.getElementById('filter-average-likes-details');
    if( filterAverageLikes != null ){
      if( filterAverageLikes.checked ){
        filterAverageLikesDetails.classList.remove('hidden');
      }else{
        filterAverageLikesDetails.classList.add('hidden');
      }
    }

    let filterCharacterLimit = document.getElementById('filter-character-limit');
    let filterCharacterLimitDetails = document.getElementById('filter-character-limit-details');
    if( filterCharacterLimit != null ){
      if( filterCharacterLimit.checked ){
        filterCharacterLimitDetails.classList.remove('hidden');
      }else{
        filterCharacterLimitDetails.classList.add('hidden');
      }
    }
  }
  setIntervalWithTurbo(refreshSearchResultCount, 200);



  let minLikesSlider = document.getElementById("average-likes-minimum");
  let minLikesSliderOutput = document.getElementById("average-likes-minimum-value");
  if( minLikesSlider != null ) {
      minLikesSliderOutput.innerHTML = minLikesSlider.value; // Display the default slider value
    
      // Update the current slider value (each time you drag the slider handle)
      minLikesSlider.oninput = function() {
        minLikesSliderOutput.innerHTML = this.value;
      } 
  }

  let maxLikesSlider = document.getElementById("average-likes-maximum");
  let maxLikesSliderOutput = document.getElementById("average-likes-maximum-value");
  if( maxLikesSlider != null ) {
      maxLikesSliderOutput.innerHTML = maxLikesSlider.value; // Display the default slider value
    
      // Update the current slider value (each time you drag the slider handle)
      maxLikesSlider.oninput = function() {
        maxLikesSliderOutput.innerHTML = this.value;
      } 
  }

  let characterLimitSlider = document.getElementById("character-limit-maximum");
  let characterLimitSliderOutput = document.getElementById("character-limit-maximum-value");
  if( characterLimitSlider != null ) {
      characterLimitSliderOutput.innerHTML = characterLimitSlider.value; // Display the default slider value
    
      // Update the current slider value (each time you drag the slider handle)
      characterLimitSlider.oninput = function() {
        characterLimitSliderOutput.innerHTML = this.value;
      } 
  }


  document.body.addEventListener("ajax:complete", (event) => {
    let analyzeForm = document.querySelector("#analyze_searched_hashtag_form");
    if( analyzeForm != null ){
      document.querySelector("#analyze_searched_hashtag_form").addEventListener("submit", function(e){  
        refreshResults();
      });
    }

    let hashtagPending = document.getElementById('hashtag-pending');
    if( hashtagPending != null ){
      setTimeout(()=>{
        refreshResults();
      },400);
    }
  });


  function retryResults(){
    var query = document.getElementById('query');
    if( query != null ){
      var queryValue = query.value;
    }
    var searchButton = document.getElementById('search-submit');
    if( searchButton != null ){
      var searchButtonText = searchButton.textContent;
      var searchButtonClicked = searchButton.getAttribute('data-clicked');
    }
    var searchedHashtag = document.getElementById('searched-hashtag');
    var searchedCompetitor = document.getElementById('searched-competitor');

    /*if( query != null && searchedHashtag == null && searchedCompetitor == null && queryValue != "" && searchButtonClicked == "true"){
      searchButton.click();
    }*/

    var showAccountHashtagsButton = document.getElementById('show-account-hashtags');
    var showAccountPostsButton = document.getElementById('show-account-posts');

    if(showAccountHashtagsButton != null  ){
      showAccountHashtagsButton.onclick = function(){
        if( showAccountHashtagsButton.classList.contains("active") ){
  
        }else{
          var accountHashtags = document.getElementById("account-hashtags");
          var accountPosts = document.getElementById("account-posts");
          if( accountHashtags != null ){
            document.getElementById("account-hashtags").classList.remove('hidden');
          }
          if( accountPosts != null ){
            document.getElementById("account-posts").classList.add('hidden');
          }
          showAccountPostsButton.classList = "bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 w-1/2 rounded-r";
          showAccountHashtagsButton.classList = "active bg-gray-100 hover:bg-gray-100 text-primary font-bold py-2 px-4 w-1/2 rounded-l";
        }
      }
    }
    if(showAccountPostsButton != null  ){ 
      showAccountPostsButton.onclick = function(){
        if( showAccountPostsButton.classList.contains("active") ){
  
        }else{
          var accountHashtags = document.getElementById("account-hashtags");
          var accountPosts = document.getElementById("account-posts");
          if( accountHashtags != null ){
            document.getElementById("account-hashtags").classList.add('hidden');
          }
          if( accountPosts != null ){
            document.getElementById("account-posts").classList.remove('hidden');
          }
          showAccountHashtagsButton.classList = "bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 w-1/2 rounded-l";
          showAccountPostsButton.classList = "active bg-gray-100 hover:bg-gray-100 text-primary font-bold py-2 px-4 w-1/2 rounded-r";
        }
      }

      // Required here for my_account and search_competitors
      var selectAllToggle = document.getElementById('select-all');
      if ( selectAllToggle != null ){
          document.getElementById('select-all').onclick = function(){
              toggle(selectAllToggle);
          }
      }
    }
  }
  setIntervalWithTurbo(retryResults, 1000);

  var showAccountHashtagsButton = document.getElementById('show-account-hashtags');
  var showAccountPostsButton = document.getElementById('show-account-posts');


  if( showAccountHashtagsButton != null ){
    showAccountHashtagsButton.onclick = function(){
      if( showAccountHashtagsButton.classList.contains("active") ){
  
      }else{
        document.getElementById("account-hashtags").classList.remove('hidden');
        document.getElementById("account-posts").classList.add('hidden');
        showAccountPostsButton.classList = "bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 w-1/2 rounded-r";
        showAccountHashtagsButton.classList = "active bg-gray-100 hover:bg-gray-100 text-primary font-bold py-2 px-4 w-1/2 rounded-l";
      }
    }
  }

  if( showAccountPostsButton != null ){
    showAccountPostsButton.onclick = function(){
      if( showAccountPostsButton.classList.contains("active") ){
  
      }else{
        document.getElementById("account-hashtags").classList.add('hidden');
        document.getElementById("account-posts").classList.remove('hidden');
        showAccountHashtagsButton.classList = "bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 w-1/2 rounded-l";
        showAccountPostsButton.classList = "active bg-gray-100 hover:bg-gray-100 text-primary font-bold py-2 px-4 w-1/2 rounded-r";
      }
    }
  }


});
