import Flickity from 'flickity';

window.addEventListener('turbo:load', eventHandler, false);
window.addEventListener('load', eventHandler, false); 


function eventHandler(event) {
    if( document.querySelector('.strategy__carousel') != null ){ 
        var flkty = new Flickity( '.strategy__carousel', {
            draggable: true, 
            pageDots: true, 
            prevNextButtons: true, 
            contain: true, 
            wrapAround: false, 
            autoplay: false, 
            adaptiveHeight: true,
            selectedAttraction: 0.2,
            friction: 0.8
        });
        document.addEventListener('click', function (event) {
            if (event.target.dataset.linkToAddFieldId == null && event.target.dataset.linkToRemoveField) return;
            window.dispatchEvent(new Event('resize'));
        }); 

    }     
}